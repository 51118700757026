<template>
    <div
        class="dashboard-container discontinued-prod"
        :class="{
            preload: isLoading,
            sectionShow: isSectionShow,
            'icon-container': isError || !hasProdData
        }"
    >
        <div class="dashboard-title">停售嚴選商品</div>
        <template v-if="hasProdData || isLoading">
            <div class="data-header">
                <span>保險公司</span>
                <span>商品名稱</span>
                <span>停售日期</span>
            </div>
            <DashboardModal
                modalTitle="停售嚴選商品"
                :title="modalData?.title"
                :subTitle="modalData?.company"
                :files="modalData?.files"
                :content="modalData?.content"
                dateTitle="停售日期"
                :date="modalData?.offSaleDate"
                v-model:isShowModalProp="isShowModal"
                @close="turnOffModal"
            />
            <ul
                class="data-list"
                :class="{ 'list-scroll-bar-space': prodData?.length > 5 }"
            >
                <li
                    v-for="(prod, idx) in prodData"
                    :key="idx"
                    class="list-item"
                    :class="{
                        'text-line': isLoading,
                        'list-item-bg': !isLoading,
                        'list-item-hide': !isMobileExpand && idx > 1
                    }"
                >
                    <template v-if="!isLoading">
                        <span class="company">
                            <div class="list-item-mobile-title">保險公司</div>
                            {{ prod.company }}
                        </span>
                        <span class="name">
                            <div class="list-item-mobile-title">商品名稱</div>
                            {{ prod.title }}
                        </span>
                        <span class="date">
                            <div class="list-item-mobile-title">停售日期</div>
                            {{ prod.offSaleDate?.replace(/-/g, '.') }}
                        </span>
                        <span class="link">
                            <div @click="turnOnModal(idx)">
                                <img
                                    src="@/assets/images/dashboard/icon_downloadcontent@3x.webp"
                                    alt="document link"
                                />
                            </div>
                        </span>
                    </template>
                </li>
                <div
                    v-show="!isLoading && prodData?.length > 2"
                    class="data-list-mobile-toggle"
                    @click="toggleMobileExpand"
                >
                    <figure>
                        <img
                            :class="[{ rotate: isMobileExpand }]"
                            src="@/assets/images/btn_sort_arrow.webp"
                            alt="展開資料按鈕"
                        />
                    </figure>
                </div>
            </ul>
            <div class="remark">{{ remarkText }}</div>
        </template>
        <DashboardNoData
            v-if="!isLoading && !hasProdData && !isError"
            message="沒有近兩週的停售嚴選商品資訊喔"
        />
        <div v-if="isError && !isLoading" class="error">
            <DashboardErrorMessage
                @reacquire="getDiscontinuedProd"
                :hasPermission="$store.state.userInfo.hasExternalPerm"
                functionName="getDashboardDiscontinuedProdAPI"
            />
        </div>
    </div>
</template>
<script>
import DashboardNoData from '@/containers/home/DashboardNoData.vue'
import DashboardErrorMessage from '@/containers/home/DashboardErrorMessage.vue'
import { getDashboardDiscontinuedProdAPI } from '@/assets/javascripts/api.js'
import DashboardModal from './DashboardModal.vue'

export default {
    name: 'DashboardDiscontinuedProd',
    components: {
        DashboardNoData,
        DashboardErrorMessage,
        DashboardModal
    },
    methods: {
        getDiscontinuedProd: async function () {
            this.isLoading = true
            this.isError = false
            try {
                if (!this.$store.state.userInfo?.hasExternalPerm)
                    throw new Error('no permission')
                let res = await getDashboardDiscontinuedProdAPI()
                this.dataList = res?.data?.nearlyOffSaleProducts
            } catch (e) {
                this.isError = true
                this.dataList = null
            } finally {
                this.isLoading = false
            }
        },
        turnOnModal: function (idx) {
            this.modalDataIdx = idx
            this.isShowModal = true
            this.$setGaEvent(`turnOnDiscontinuedProdModal`, 'click-icon')
        },
        turnOffModal: function () {
            this.isShowModal = false
        },
        toggleMobileExpand: function () {
            this.$setGaEvent(`DiscontinuedProdExpand`, 'click-icon')
            this.mobileExpanding.state = true
            this.mobileExpanding.scrollY = window.scrollY
            this.isMobileExpand = !this.isMobileExpand
        }
    },
    computed: {
        hasProdData: function () {
            let data = this.dataList
            return data && data.length !== 0
        },
        modalData: function () {
            return this.prodData && this.prodData[this.modalDataIdx]
        },
        prodData: function () {
            if (this.isLoading) return this.dummyData
            return this.dataList
        },
        isSectionShow: function () {
            return !this.$store.state.isSectionHidden
        }
    },
    data() {
        return {
            dummyData: [{}, {}, {}, {}, {}],
            remarkText: '備註/停售規則：2週內的停售嚴選商品',
            isLoading: false,
            isError: false,
            isShowModal: false,
            isMobileExpand: false,
            modalDataIdx: 0,
            mobileExpanding: { state: false, scrollY: 0 },
            dataList: null
        }
    },
    async mounted() {
        await this.getDiscontinuedProd()
    },
    updated() {
        if (this.mobileExpanding?.state) {
            window.scrollTo(0, this.mobileExpanding.scrollY)
            this.mobileExpanding.state = false
        }
    }
}
</script>
<style lang="scss" scoped>
@mixin scrollBar() {
    &::-webkit-scrollbar {
        width: 5px;
    }

    &::-webkit-scrollbar-thumb {
        background: $sum-row-grey;
        border-radius: 15px;
    }
}

@mixin slim-vw() {
    padding-left: 10px;
    padding-right: 10px;

    .data-list {
        &.list-scroll-bar-space {
            padding-right: 3px;
            margin-right: -7px;
        }

        &::-webkit-scrollbar {
            width: 4px;
        }
    }
}

.discontinued-prod {
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    max-width: 583px;
    padding: 10px 20px;
    font-size: 12px;
    color: $secondary-grey;
    position: relative;
    height: 459px;

    &.icon-container {
        min-height: 300px;
    }

    &.sectionShow {
        @include slim-vw;
    }

    .data-header,
    .data-list .list-item {
        display: grid;
        grid-template-columns: 92px 1fr 100px 60px;
        place-items: center;
        gap: 10px;
        min-height: 40px;
    }
    .data-header {
        font-size: 15px;
    }

    .data-list {
        list-style: none;
        display: flex;
        flex-direction: column;
        height: 274px;
        padding: 0 0 5px 0;
        margin: 10px 0;
        overflow: auto;
        @include scrollBar();
        &.list-scroll-bar-space {
            padding-right: 10px;
            margin-right: -15px;
        }

        .list-item {
            border-radius: 5px;
            box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
            margin-bottom: 8px;
            &.list-item-bg {
                background: $primary-grey;
            }

            .list-item-mobile-title {
                display: none;
            }

            .company,
            .name {
                text-align: center;
                padding: 3px 7.5px;
                display: -webkit-box;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
                overflow: hidden;
                text-overflow: ellipsis;
            }

            .date {
                font-size: 14px;
                font-weight: 500;
            }

            .link {
                div {
                    display: flex;
                    align-items: center;
                    img {
                        height: 20px;
                        cursor: pointer;
                    }
                }
            }
        }

        .data-list-mobile-toggle {
            display: none;
        }
    }

    .remark {
        border-top: 1px solid $sum-row-grey;
        padding-top: 10px;
        color: $eleventh-grey;
    }

    .error,
    .no-data {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }

    @media screen and (max-width: 1280px) {
        @include slim-vw;
    }

    @media screen and (max-width: 1018px) {
        max-width: 100%;
    }

    @media screen and (max-width: 578px) {
        padding: 10px;
        height: auto;
        .data-header {
            display: none;
        }

        .data-list {
            overflow: hidden;
            height: auto;
            margin: 0;
            &.list-scroll-bar-space {
                padding-right: 0;
                margin-right: 0;
            }

            .list-item {
                box-sizing: border-box;
                display: flex;
                gap: 0;
                flex-direction: column;
                min-height: 129px;
                padding: 10px;
                margin-bottom: 10px;
                &.list-item-hide {
                    display: none;
                }

                .link,
                .company,
                .name {
                    margin-bottom: 10px;
                }

                .company,
                .date,
                .name {
                    width: 100%;
                    flex-grow: 1;
                    display: grid;
                    grid-template-columns: 80px 1fr;
                    text-align: right;
                    padding: 0;
                    .list-item-mobile-title {
                        display: block;
                        text-align: left;
                        font-weight: 500;
                    }
                }

                .date {
                    font-size: 12px;
                    font-weight: 400;
                }

                .link {
                    order: -1;
                    display: flex;
                    justify-content: flex-end;
                    width: 100%;
                }
            }

            .data-list-mobile-toggle {
                display: block;

                figure {
                    width: 17px;
                    height: 17px;
                    margin: 0 auto;
                    img {
                        width: 100%;
                        height: 100%;
                        object-fit: contain;
                    }
                }

                .rotate {
                    transform: rotateX(180deg);
                }
            }
        }
    }
}
</style>
